var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"},[_c('h1',{staticClass:"font-bold text-lg text-center text-darkblue mb-3"},[_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "New Vehicle" : "Update Vehicle")+" ")]),_c('div',{staticClass:"text-center mt-1 mb-2"},[(_vm.loading.vehicle)?_c('font-awesome-icon',{class:'text-dark animate-spin text-base mr-1 ',attrs:{"title":"loading","icon":['fas', 'spinner']}}):_vm._e()],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SubmitVehicle)}}},[_c('div',{staticClass:" justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"FullName"}},[_vm._v("Plate Number "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle.plateNumber),expression:"vehicle.plateNumber"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"Plate Number"},domProps:{"value":(_vm.vehicle.plateNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "plateNumber", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Amount"}},[_vm._v("Manufacturer "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|custom_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle.manufacturer),expression:"vehicle.manufacturer"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","name":"manufacturer"},domProps:{"value":(_vm.vehicle.manufacturer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "manufacturer", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"  justify-around flex flex-col md:flex-row"},[_c('div',{staticClass:"w-full md:w-5/12 mb-3 md:mb-6 "},[_c('label',{staticClass:"block md:text-xs",attrs:{"for":"Position"}},[_vm._v("Model "),_c('span',{staticClass:"text-red-700"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required|custom_text"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehicle.model),expression:"vehicle.model"}],staticClass:"border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block",attrs:{"type":"text","placeholder":"E.g Driver,Manager","name":"Position"},domProps:{"value":(_vm.vehicle.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.vehicle, "model", $event.target.value)}}}),_c('span',{staticClass:"text-red-700 md:text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('button',{staticClass:"text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full",attrs:{"type":"submit"}},[(_vm.loading.spinner)?_c('svg',{staticClass:"inline animate-spin mr-2",attrs:{"width":"25","height":"25","viewBox":"0 0 50 50","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M48 25C48 12.2975 37.7025 2 25 2","stroke":"black","stroke-width":"4"}}),_c('path',{attrs:{"d":"M48 25C48 37.7025 37.7025 48 25 48","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M2 25C2 12.2975 12.2975 2 25 2","stroke":"white","stroke-width":"4"}}),_c('path',{attrs:{"d":"M25 48C12.2975 48 2 37.7025 2 25","stroke":"#C9000C","stroke-width":"4"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.mode == "new" ? "Create Vehicle" : "Update Vehicle")+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }