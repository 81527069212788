<template>
  <div
    class="ml-auto mr-auto p-2  md:p-6 bg-white rounded-lg mb-4 md:max-w-3/4"
  >
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      {{ $route.params.mode == "new" ? "New Vehicle" : "Update Vehicle" }}
    </h1>
    <div class="text-center mt-1 mb-2">
      <font-awesome-icon
        v-if="loading.vehicle"
        title="loading"
        :class="'text-dark animate-spin text-base mr-1 '"
        :icon="['fas', 'spinner']"
      />
    </div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(SubmitVehicle)" class="">
        <div class=" justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="FullName"
              >Plate Number <span class="text-red-700">*</span>
            </label>
            <validation-provider rules="required|alpha_num" v-slot="{ errors }">
              <input
                type="text"
                v-model="vehicle.plateNumber"
                name="Plate Number"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Amount"
              >Manufacturer <span class="text-red-700">*</span>
            </label>
            <validation-provider
              rules="required|custom_text"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="vehicle.manufacturer"
                name="manufacturer"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="  justify-around flex flex-col md:flex-row">
          <div class="w-full md:w-5/12 mb-3 md:mb-6 ">
            <label class="block md:text-xs" for="Position"
              >Model <span class="text-red-700">*</span></label
            >
            <validation-provider
              rules="required|custom_text"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="vehicle.model"
                placeholder="E.g Driver,Manager"
                name="Position"
                class="border-2 md:text-xs md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>

        <button
          type="submit"
          class="text-white block md:text-xs bg-darkblue hover:text-black hover:bg-green-400 pl-3 pt-2 pb-2 pr-3 ml-auto mr-auto rounded-full"
        >
          <svg
            v-if="loading.spinner"
            class="inline animate-spin mr-2"
            width="25"
            height="25"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48 25C48 12.2975 37.7025 2 25 2"
              stroke="black"
              stroke-width="4"
            />
            <path
              d="M48 25C48 37.7025 37.7025 48 25 48"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M2 25C2 12.2975 12.2975 2 25 2"
              stroke="white"
              stroke-width="4"
            />
            <path
              d="M25 48C12.2975 48 2 37.7025 2 25"
              stroke="#C9000C"
              stroke-width="4"
            />
          </svg>
          {{
            $route.params.mode == "new" ? "Create Vehicle" : "Update Vehicle"
          }}
        </button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { mapMutations } from "vuex";

  export default {
    name: "ManageVehicle",
    props: {},
    components: { FontAwesomeIcon, ValidationProvider, ValidationObserver },
    apollo: {
      vehicle: {
        query: gql`
          query vehicle($id: ID!) {
            vehicle(id: $id) {
              _id
              plateNumber
              manufacturer
              model
              authorId {
                _id
              }
              serialId
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        skip() {
          return this.$route.params.mode != "edit";
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.vehicle = true;
            return;
          }

          this.loading.vehicle = false;
        },
      },
    },
    data() {
      return {
        loading: {
          vehicle: false,
          spinner: false,
        },
        vehicle: {
          _id: null,
          plateNumber: null,
          manufacturer: null,
          model: null,
          authorId: {
            _id: null,
            name: null,
          },
        },
      };
    },
    watch: {},
    computed: {
      // roleOptions: function() {
      //   return this.getRoles.map((role) => {
      //     return {
      //       value: role._id,
      //       text: role.name,
      //     };
      //   });
      // },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      SubmitVehicle() {
        if (this.$route.params.mode != "edit") {
          this.CreateVehicle();
          return;
        }
        this.UpdateVehicle();
      },
      async CreateVehicle() {
        this.loading.spinner = true;
        try {
          const { manufacturer, plateNumber, model } = this.vehicle;
          const vehicleData = { manufacturer, plateNumber, model };
          delete vehicleData._id;
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($vehicle: VehicleInput!) {
                createVehicle(vehicle: $vehicle) {
                  _id
                  model
                  manufacturer
                  plateNumber
                }
              }
            `,
            // Parameters
            variables: {
              vehicle: vehicleData,
            },
          });

          this.loading.spinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.createVehicle._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Vehicle created successfully,`,
            });

            return this.$router.push("/app/vehicles");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating vehicle,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.spinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating vehicle,check your network or contact your developer`,
          });
        }
      },
      async UpdateVehicle() {
        this.loading.spinner = true;
        try {
          const { manufacturer, plateNumber, model } = this.vehicle;

          const vehicleData = { manufacturer, plateNumber, model };
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $vehicle: VehicleInput!) {
                updateVehicle(id: $id, vehicle: $vehicle) {
                  _id
                  plateNumber
                  model
                  manufacturer
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              vehicle: vehicleData,
            },
          });

          this.loading.spinner = false;
          if (!!errors && data == null) {
            const errorResponse = errors.find((error) => {
              return error.extensions.code == "BAD_USER_INPUT";
            });
            return this.$emit("show-alert", {
              type: "error",
              message: errorResponse.message,
            });
          }

          if (!errors && !!data.updateVehicle._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Vehicle updated successfully`,
            });

            return this.$router.push(`/app/vehicles/${data.updateVehicle._id}`);
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating vehicle,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.spinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating user,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Vehicles", pathName: "vehicles" },
        ...(this.$route.params.mode == "edit"
          ? [
              {
                displayName: "Single Vehicle",
                pathName: "singleVehicle",
                params: { id: this.$route.params.id },
              },
            ]
          : []),
        {
          displayName: "Manage Vehicle",
          pathName: "manageVehicle",
          params: { id: this.$route.params.id, mode: this.$route.params.mode },
        },
      ]);

      this.$apollo.queries.vehicle.setOptions({
        fetchPolicy: "network-only",
      });
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
